import React, { Component } from 'react';
import { render } from 'react-dom';

import { init, locations } from '@contentful/app-sdk';
import '@contentful/forma-36-react-components/dist/styles.css';
import '@contentful/forma-36-fcss/dist/styles.css';
import { Heading, Note, Form, FormLabel, TextField } from "@contentful/forma-36-react-components";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = { parameters: { baseUrl: null, uidDictionary: 'alphanum_lower', uidLength: 10 } };
    this.app = this.props.sdk.app;
    this.app.onConfigure(() => this.onConfigure());
  }

  async componentDidMount() {
    const parameters = await this.app.getParameters();
    this.setState({ parameters: parameters || { baseUrl: null, uidDictionary: 'alphanum_lower', uidLength: 10 } }, () =>
      this.app.setReady()
    );
  }

  handleChange(event) {
    let parameters = this.state.parameters;
    parameters[event.target.name] = event.target.value;
    this.setState({
      parameters,
    });
  }

  render() {
    return (
      <Form id="app-config">
        <Heading>Activity QR Code URL Config</Heading>
        <Note noteType="primary" title="About the app">
          Set the base URL and the dictionary/length for the URL genarator
        </Note>
        <FormLabel htmlFor="baseUrl">Set the Base URL</FormLabel>
        <TextField
          name="baseUrl"
          labelText="Base URL"
          value={this.state.parameters.baseUrl}
          onChange={e => this.handleChange(e)}
        ></TextField>
        <FormLabel htmlFor="uidDictionary">Set Dictionary</FormLabel>
        <TextField
          name="uidDictionary"
          labelText="Dictionary"
          value={this.state.parameters.uidDictionary}
          onChange={e => this.handleChange(e)}
        ></TextField>
        <FormLabel htmlFor="uidLength">Set Length</FormLabel>
        <TextField
          name="uidLength"
          labelText="Length"
          value={this.state.parameters.uidLength}
          onChange={e => this.handleChange(e)}
        ></TextField>
      </Form>
    );
  }

  async onConfigure() {
    const { items: contentTypes } = await this.props.sdk.space.getContentTypes();

    return {
      parameters: this.state.parameters,
    };
  }
}

init((sdk) => {
  const root = document.getElementById('root');
  if (sdk.location.is(locations.LOCATION_APP_CONFIG)) {
    render(<Config sdk={sdk} />, root);
  } else {
    throw new Error('rendered outside of config location');
  }
});
